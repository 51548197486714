<template>
  <div class="home">
    <BigHeader />
    <div class="container">
      <p class="is-size-1">Nähmaschinen</p>
      <p>
        <a  class="button" href="https://naeharena-shop.de/Naehmaschinen" target="_blank">Hier gehts direkt zu unserem Nähmaschinen-Shop</a><br/><br/>
        Wenn Du das Nähen liebst, solltest Du auch Deine Nähmaschine lieben.<br/>
        Wir helfen Dir die Maschine zu finden die zu Dir passt.<br/><br/>

        Egal ob Du eine Nähmaschine, Overlock, Coverlock oder Stickmaschine  bei uns kaufst, bekommst Du von uns eine kostenfreie Einweisung am Tag der Abholung. Denn wir lassen Dich nicht einfach mit der Maschine losziehen, und möchten gerne, dass ihr zwei von Anfang an richtig Spaß miteinander habt.<br/><br/>

        Wenn Du eine Maschine über unseren Online-Shop kaufen möchtest, und du Fragen zu ihr bzw. Organisation hast, dann können wir das gerne bei einem Telefontermin besprechen. Da Du dann Deine Maschine zugeschickt bekommst, werden wir Dir ein Einweisungsvideo zukommen lassen, damit auch Du gleich viel Spaß mit Deiner neuen Freundin hast.<br/><br/>

        Sollte sie dann mal „Sperenzien“ machen sind wir für Euch zwei da.<br/>
        Wir videotelefonieren gerne mit Dir, um die Fehler zu erkennen und vllt. gleich zu lösen, bzw. Weiteres zu vereinbaren. Sollten wir mit unserer Hilfe nicht mehr weiter kommen, geht sie direkt zum Elna-Service. Entweder über uns, oder sie wird bei Dir Zuhause abgeholt. Du siehst, Du und Deine Maschine sind bei uns bestens betreut.<br/>
        Alle 2 Jahre sollte Deine Freundin mal durchgecheckt werden. Dazu bieten wir den Inspektionsservice an. Entweder bei unserem Partner oder bei Elna werden die Maschinen auf Herz und Nieren geprüft und wieder aufgepäppelt, damit ihr wieder viel gute Nähzeit miteinander verbringen könnt.<br/>
        Solltest Du hierzu Fragen haben, dann darfst Du uns gerne anrufen.<br/>
        Gerne nehmen wir zur Inspektion auch Maschinenfreundinnen anderer Hersteller an.<br/><br/>

        Wir bieten Dir für Dich und Deine Lieblingsmaschine Maschinentrainings und Webinare an, um noch mehr aus Deiner Maschine raus zu holen. Du lernst Kniffe, Tipps und Tricks rund um Deine Elna-Maschine.<br/><br/>

        Damit Du Dir ein Bild einiger Elna-Maschinen machen kannst, haben wir hier Links zu unseren Vorstellungs-Videos für Dich. Gerne kannst Du auch in unseren YouTube Kanal die Maschinen in den Nähvideos in Aktion beobachten.<br/><br/>

        <a href="https://www.youtube.com/watch?v=kTPECv6YDgo" target="_blank">Elna 770</a><br/>
        <a href="https://www.youtube.com/watch?v=N4R7mHK8nsk" target="_blank">Elna 780+</a><br/>
        <a href="https://www.youtube.com/watch?v=8h-Hid42czc" target="_blank">Elna 720pro</a><br/>
        <a href="https://www.youtube.com/watch?v=mVLgf6HtC50" target="_blank">Elna 680+</a><br/>
        <a href="https://www.youtube.com/watch?v=iKHYsnM3eOw" target="_blank">Elna 560 und 570a</a><br/>
        <a href="https://www.youtube.com/watch?v=N0JTsSV0Njs" target="_blank">Elna 450</a><br/>
        <a href="https://www.youtube.com/watch?v=Ntnx5R24P94" target="_blank">Elna 830L</a><br/>
        <a href="https://www.youtube.com/watch?v=1z1zsycgbBs" target="_blank">864air (Nähvideo Lina)</a><br/>
      </p>
    </div>
  </div>
</template>

<script>

import BigHeader from "@/components/BigHeader";

export default {
  name: 'Machines',
  components: {
    BigHeader
  }
}
</script>
