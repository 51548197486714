<template>
  <div id="app">
    <WhatsAppOrderModal
        :is-open="this.whatsAppOrderModalOpen"
        :toggle-whats-app-order-modal="this.toggleWhatsAppOrderModal"
    />
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item navbar-title" href="/">
          <img src="./assets/logo_minimal.png" style="width: 100px;" />
        </a>

        <a
            role="button"
            :class="'navbar-burger burger' + (navbarActive ? ' is-active' : '')"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            @click="toggleNav"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" :class="'navbar-menu' + (navbarActive ? ' is-active' : '')">
        <div class="navbar-start">

          <a class="navbar-item" @click="hideNav">
            <router-link to="/">Home</router-link>
          </a>

          <a class="navbar-item" href="https://naeharena-shop.de/" target="_blank">Shop</a>

          <a class="navbar-item" href="https://naeharena-shop.de/Naehevents" target="_blank">Nähevents & Webinare</a>

          <a class="navbar-item" @click="hideNav">
            <router-link to="/machines">Nähmaschinen</router-link>
          </a>

          <a class="navbar-item" href="https://schwarzwald-tani.de" target="_blank">Schwarzwald-Tani</a>

          <a class="navbar-item" @click="hideNav">
            <router-link to="/contact">Kontakt</router-link>
          </a>
        </div>
      </div>
    </nav>

    <router-view/>

    <footer class="footer">

      <div class="container">
        <div class="columns">
          <div class="column">
            <router-link to="/imprint">Impressum</router-link> | <router-link to="/privacy">Datenschutz</router-link>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <p class="is-size-3">Näharena Bühl</p>

            <p><b>Kontakt</b><br/>
              Tel.: 07223 / 24566<br/>
              Mail: <a href="mailto:kontakt@naeharena.de">kontakt@naeharena.de</a><br/>
              WhatsApp: 01729372203<br/><br/></p>
            <p><b>Anschrift</b><br/>
              Sternenstr. 5<br/>
              77815 Bühl</p>
          </div>
          <div class="column is-one-third">
            <img src="./assets/_Näharena_Logo.png" />
          </div>
          <div class="column is-one-third has-text-right">

            <p class="is-size-3">Social Media</p>
            <div class="content">
              <p>
                <a href="https://www.instagram.com/schwarzwaldtani" target="_blank" class="button is-large" style="margin-right: 10px;">
            <span class="icon">
              <i class="fab fa-instagram"></i>
            </span>
                </a>

                <a href="https://de-de.facebook.com/naehvideos/" target="_blank" class="button is-large" style="margin-right: 10px;">
            <span class="icon">
              <i class="fab fa-facebook"></i>
            </span>
                </a>

                <a href="https://www.youtube.com/channel/UCLkilf7gDTvibPuyZNCgjHQ" target="_blank" class="button is-large">
            <span class="icon">
              <i class="fab fa-youtube"></i>
            </span>
                </a>
                <br/>

              </p>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <p>
              <b>Neue Öffnungszeiten!</b><br/>
              <b>Montag, Donnerstag und Freitag:</b> 10 - 13 Uhr & 14 - 18 Uhr<br/>
              <b>Samstag:</b> 10 - 13 Uhr<br/>
              Unser <a href="https://naeharena-shop.de/" target="_blank">Onlineshop</a> hat natürlich durchgehend geöffnet.<br/><br/>
            </p>
          </div>
        </div>

      </div>

      <cookie-law>
        <div slot="message">
          Diese Website benutzt Cookies. Wenn du die Website weiter nutzt, gehen wir von deinem Einverständnis aus. Hier geths zur <router-link to="/privacy">Datenschutzerklärung</router-link>
        </div>
      </cookie-law>
    </footer>
  </div>

</template>
<script>
import {Component, Vue} from 'vue-property-decorator';
import CookieLaw from 'vue-cookie-law'
import WhatsAppOrderModal from "@/components/WhatsAppOrderModal";

@Component({
  components: {WhatsAppOrderModal, CookieLaw }
})

export default class Home extends Vue {

  navbarActive = false
  whatsAppOrderModalOpen = false

  ls_whatsAppOrderModalSeen = 'clickAndMeetModalSeen'

  mounted() {
    if (localStorage.getItem(this.ls_whatsAppOrderModalSeen) !== 'true') {
      // COMMENT IN IF YOU WANT TO SHOW A MODAL BASED ON LOCALSTORAGE AGAIN
      // this.whatsAppOrderModalOpen = true
    }
  }

  toggleWhatsAppOrderModal() {
    this.whatsAppOrderModalOpen = !this.whatsAppOrderModalOpen

    if (!this.whatsAppOrderModalOpen) {
      localStorage.setItem(this.ls_whatsAppOrderModalSeen, 'true')
    }

  }

  toggleNav() {
    this.navbarActive = !this.navbarActive
  }

  hideNav() {
    this.navbarActive = false
  }

}

</script>

<style>

@font-face {
  font-family: "Rambla";
  src: url('./fonts/Rambla/Rambla-Regular.ttf') format("truetype");
}

* {
  font-family: 'Rambla', sans-serif !important;
}

.navbar {
  background: #a31d18 !important;
}

a {
  color: #a31d18 !important;
}

.navbar-item, .navbar-item a {
  color: #ffffff !important;
}

.navbar-item:hover, .navbar-item:focus, .navbar-item:focus-within {
  background: #a31d18 !important;
}

.slick-prev:before, .slick-next:before {
  color:red !important;
}

.slick-track {
  display: flex;
}

.slick-track .slick-slide{
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.navbar-burger {
  color: #fff !important;
}

.navbar-menu {
  background: #a31d18 !important;
}

.router-view {
  margin-top: 40px;
}

.footer {
  margin-top: 150px;
}

.is-naeharena {
  background: #a31d18 !important;
  color: #fff !important;
}
</style>
