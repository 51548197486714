import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import Courses from '../views/Courses.vue'
import News from '../views/News.vue'
import Contact from '../views/Contact.vue'
import Privacy from '../views/Privacy.vue'
import Imprint from '../views/Imprint.vue'
import Machines from '../views/Machines.vue'
import Agb from '../views/Agb.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/courses',
    name: 'Courses',
    component: Courses
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint
  },
  {
    path: '/agb',
    name: 'Agb',
    component: Agb
  },
  {
    path: '/machines',
    name: 'Machines',
    component: Machines
  }
]

const router = new VueRouter({
	routes,
	scrollBehavior () {
		return { x: 0, y: 0 }
	}
})

export default router
